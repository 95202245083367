<script setup lang="ts">
import { PostSmall } from '@/api/models';
import { Tag } from 'api/models/Tag';
import { StrapiLocale } from '@nuxtjs/strapi/dist/runtime/types';

const { locale, locales, t } = useI18n();
const localePath = useLocalePath();

const runtimeConfig = useRuntimeConfig();

useSeoMeta({
    title: () => t("home.meta.title") + ' - Blog Esteban Mayoral',
    ogTitle: () => t("home.meta.title") + ' - Blog Esteban Mayoral',
    description: () => t("home.meta.description"),
    ogDescription: () => t("home.meta.description"),
    ogImage: () => runtimeConfig.public.baseUrl + '/logo.png',
    twitterCard: 'summary_large_image',
    twitterImage: () => runtimeConfig.public.baseUrl + '/logo_twitter_large.png'
})

const { find } = useStrapi()


const { data: response, pending, error } = await useAsyncData('posts', () => find<PostSmall>('posts', { 
    sort: 'publishedAt:desc',
    publicationState: 'live',
    populate: ['thumbnail', 'tags', 'author'],
    fields: ['thumbnail', 'author', 'tags', 'title', 'slug', 'thumbnail_bg_color', 'publishedAt', 'description', 'locale', 'uuid'],
    locale: locale.value as StrapiLocale
}));

const tagsResponse = await useAsyncData('tags', () => find<Tag>('tags', { locale: locale.value as StrapiLocale }));

</script>

<template>
    <div class="flex flex-row gap-2 container mx-auto mt-3 mb-3">
        <section class="flex-1 grow">
            <template v-if="pending">
                Loading ...
            </template>

            <template v-if="!pending">
                <ArticleContainer  v-for="(article, index) in response?.data" :article="article"  />
            </template>
        </section>
        <!-- Categories -->
        <aside class="w-1/4 p-5 hidden lg:block">
            <h3 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{ t('home.categories') }}</h3>
            <hr>
            <template v-if="tagsResponse.pending.value">
                Loading ...
            </template>
            <template v-if="!tagsResponse.pending.value">
                <ul>
                    <li v-for="tag in tagsResponse.data.value?.data">
                        <NuxtLink :to="localePath('/tag/' + tag.attributes.tagName)" class="hover:underline">
                            <span :style="{ 'color': tag.attributes.tagColor }">#</span>{{ tag.attributes.tagName }}
                        </NuxtLink>
                    </li>
                </ul>
            </template>

        </aside>
    </div>
</template>

